import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/projects/project"

const Projects = ({ data }) => {
  const {
    allContentfulProject: { nodes },
  } = data
  return (
    <Layout>
      <SEO title="Projects" />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 90%;
          margin: 70px 20px;
        `}
      >
        {nodes.map(node => {
          const {
            name,
            image: {
              file: { url },
            },
            description: {
              childMarkdownRemark: { html },
            },
          } = node
          return <Project name={name} html={html} url={url} key={name} />
        })}
      </div>
    </Layout>
  )
}

Projects.propTypes = {
  data: PropTypes.shape({
    allContentfulProject: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query projects($lang: String = "en-US") {
    allContentfulProject(
      filter: { node_locale: { eq: $lang } }
      sort: { fields: order }
    ) {
      nodes {
        name
        image {
          file {
            url
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default Projects
