import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"

const Project = ({ name, html, url }) => (
  <div
    css={css`
      display: flex;
      margin: 20px 0px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.5);

      :last-child {
        border-bottom: none;
      }

      @media (max-width: 640px) {
        flex-direction: column;
      }
    `}
  >
    <div
      css={css`
        flex: 3;
        display: flex;
        justify-content: start;
        flex-direction: column;
      `}
    >
      <h1
        css={css`
          color: #e5cccc;
          margin: 0px;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 40px;
          line-height: 1.2em;
          width: 90%;
        `}
      >
        {name}
      </h1>
      <div
        css={css`
          margin: 20px 0px;
          width: 90%;
        `}
      >
        <img
          css={css`
            width: 100%;
          `}
          src={url}
          alt="project"
        />
      </div>
    </div>
    <div
      css={css`
        margin: 10px 0px;
        flex: 4;
        font-size: 16px;
        color: #f8f2f2;
        font-weight: 500;
        letter-spacing: 0.02em;

        p {
          margin-top: 0px;
        }
      `}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </div>
)

Project.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
}

export default Project
